// SvgIcon.js
import React from "react";

const SvgIcon = ({ onClick }) => (
  <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#ffffff"
            onClick={onClick}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M4.16669 9.99999V3.33333"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M15.8333 16.6667V14.1667"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M4.16669 16.6667V13.3333"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M15.8333 10.8333V3.33333"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M10 5.83333V3.33333"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M10 16.6667V9.16667"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M4.16667 13.3333C5.08714 13.3333 5.83333 12.5871 5.83333 11.6667C5.83333 10.7462 5.08714 10 4.16667 10C3.24619 10 2.5 10.7462 2.5 11.6667C2.5 12.5871 3.24619 13.3333 4.16667 13.3333Z"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M9.99998 9.16666C10.9205 9.16666 11.6666 8.42047 11.6666 7.49999C11.6666 6.57952 10.9205 5.83333 9.99998 5.83333C9.07951 5.83333 8.33331 6.57952 8.33331 7.49999C8.33331 8.42047 9.07951 9.16666 9.99998 9.16666Z"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
            <path
              d="M15.8334 14.1667C16.7538 14.1667 17.5 13.4205 17.5 12.5C17.5 11.5795 16.7538 10.8333 15.8334 10.8333C14.9129 10.8333 14.1667 11.5795 14.1667 12.5C14.1667 13.4205 14.9129 14.1667 15.8334 14.1667Z"
              stroke="#222222"
              stroke-linecap="round"
            ></path>
          </svg>
);

export default SvgIcon;
