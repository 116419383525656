// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button1 {
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  width: auto !important;
}
.color {
  color: #000;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid white;
  border-radius: 20px;
  height: 100%;
  padding: 7px;
  background-color: #fff;
}
.inputtype {
  width: 173px !important;
  height: 36px !important;
}
.ant-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
}
.popup{
  border: 1px solid white;
  padding: 13px;
  font-size: 24px;
  background-color: aliceblue;
  border-radius: 11px;
  margin-left: 20px;
}
.popupMain{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mainHeader{
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,SAAS;EACT,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".button1 {\n  font-size: 14px;\n  height: 32px;\n  padding: 4px 15px;\n  border-radius: 6px;\n  width: auto !important;\n}\n.color {\n  color: #000;\n  cursor: pointer;\n  margin-left: 20px;\n  border: 1px solid white;\n  border-radius: 20px;\n  height: 100%;\n  padding: 7px;\n  background-color: #fff;\n}\n.inputtype {\n  width: 173px !important;\n  height: 36px !important;\n}\n.ant-modal .ant-modal-title {\n  margin: 0;\n  color: rgba(0, 0, 0, 0.88);\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 1.5;\n  word-wrap: break-word;\n  display: flex;\n  justify-content: center;\n}\n.popup{\n  border: 1px solid white;\n  padding: 13px;\n  font-size: 24px;\n  background-color: aliceblue;\n  border-radius: 11px;\n  margin-left: 20px;\n}\n.popupMain{\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n.mainHeader{\n  display: flex;\n  flex-direction: row;\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
