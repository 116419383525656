// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.inputsend {
  width: 100%;
  height: 20vh;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save {
  width: 100%;
  border: 1px solid pink;
  height: 80vh;
  background-color: antiquewhite;
}
.editinput {
  height: 30%;
}
.editvaluefirst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.values {
  display: grid;
  grid-template-columns: max-content max-content;
  font-size: 15px;
  color: black;
  padding: 10px;
}
.values > *:nth-child(even)::before {
  content: ": ";
  margin: 10px;
}
.firstone {
  padding: 5px;
}
.editbutton1 {
  width: 100%;
}
.savebutton {
  width: 380px;
}
`, "",{"version":3,"sources":["webpack://./src/components/edit/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,8BAA8B;AAChC;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;EACb,8CAA8C;EAC9C,eAAe;EACf,YAAY;EACZ,aAAa;AACf;AACA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd","sourcesContent":[".container {\n  width: 100%;\n  height: 100vh;\n  margin: 0;\n  padding: 0;\n}\n.inputsend {\n  width: 100%;\n  height: 20vh;\n  border: 1px solid white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.save {\n  width: 100%;\n  border: 1px solid pink;\n  height: 80vh;\n  background-color: antiquewhite;\n}\n.editinput {\n  height: 30%;\n}\n.editvaluefirst {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 15px;\n}\n.values {\n  display: grid;\n  grid-template-columns: max-content max-content;\n  font-size: 15px;\n  color: black;\n  padding: 10px;\n}\n.values > *:nth-child(even)::before {\n  content: \": \";\n  margin: 10px;\n}\n.firstone {\n  padding: 5px;\n}\n.editbutton1 {\n  width: 100%;\n}\n.savebutton {\n  width: 380px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
