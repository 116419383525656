import React, { useState } from "react";
import "./styles.css";
import { FormSlice } from "../../slice/formSlice";
import Header from "../../components/header";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Home = () => {
  const dispatch = useDispatch();
  const initialValues = {
    state: "",
    status: "",
    serviceStatus: "",
    facility: "",
    provider: "",
    medicareBeneficiaryId: "",
    residentFirstName: "",
    residentLastName: "",
    gender: "",
    birthDate: "",
    eligibletoBillPreventiveVisit: "",
    room: "",
    medicareEffDate: "",
    cpt: "",
    dx: "",
    workDate: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const { Option } = Select;
  function handleChangeSelect(value) {
    console.log(`selected ${value}`);
  }

  const validate = (values) => {
    var valueReturn = true;
    const errors = {};
    if (values.state == "") {
      valueReturn = false;
      errors.state = "State is required!";
    }
    if (values.status == "") {
      valueReturn = false;
      errors.status = "Status is required!";
    }
    if (values.serviceStatus == "") {
      valueReturn = false;
      errors.serviceStatus = "serviceStatus is required!";
    }
    if (values.facility == "") {
      valueReturn = false;
      errors.facility = "Facility is required!";
    }
    if (values.provider == "") {
      valueReturn = false;
      errors.provider = "Provider is required!";
    }
    const mbiRegex =
      /^[1-9][A-PR-UW-Y0-9][0-9A-NP-Z0-9][0-9][A-PR-UW-Y0-9][0-9A-NP-Z0-9][0-9][A-PR-UW-Y0-9][A-PR-UW-Y0-9][0-9][0-9]$/;
    if (values.medicareBeneficiaryId === "") {
      valueReturn = false;
      errors.medicareBeneficiaryId = "Medicare Beneficiary ID is required!";
    }

    if (values.residentFirstName == "") {
      valueReturn = false;
      errors.residentFirstName = "Resident First Name is required!";
    }
    if (values.residentLastName == "") {
      valueReturn = false;
      errors.residentLastName = "Resident Last Name is required!";
    }
    if (values.gender == "") {
      valueReturn = false;
      errors.gender = "Date of Gender is required!";
    }
    if (values.birthDate == "") {
      valueReturn = false;
      errors.birthDate = "Date of Birth is required!";
    }
    if (values.Eligible_to_bill_Preventive_visit == "") {
      valueReturn = false;
      errors.Eligible_to_bill_Preventive_visit =
        "Eligible Bill Date is required!";
    }
    if (values.eligibletoBillPreventiveVisit == "") {
      valueReturn = false;
      errors.eligibletoBillPreventiveVisit = "Eligible to bill is required!";
    }
    if (values.room == "") {
      valueReturn = false;
      errors.room = "room is required!";
    }
    if (values.medicareEffDate == "") {
      valueReturn = false;
      errors.medicareEffDate = "Medicare Eff date  is required!";
    }
    if (values.cpt == "") {
      valueReturn = false;
      errors.cpt = "CPT is required!";
    }
    if (values.dx == "") {
      valueReturn = false;
      errors.dx = "DX is required!";
    }
    if (values.workDate == "") {
      valueReturn = false;
      errors.workDate = "workDate is required!";
    }
    setFormErrors(errors);
    return valueReturn;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "medicareBeneficiaryId" && value.length >= 13) {
      return;
    }

    if (name === "state") {
      setFormValues({
        ...formValues,
        [name]: Array.isArray(value) ? value.join(",") : value,
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    const fieldErrors2fieldErrors = validate({ [name]: value });
    const fieldErrors2 = {};
    if (name === "medicareBeneficiaryId" && value.length > 0) {
      const lastIndex = value.length - 1;
      const character = value[lastIndex];
      if (lastIndex === 0) {
        fieldErrors2[name] =
          "The first character must be a numeric value from 1 to 9.";
      } else if (lastIndex === 1) {
        fieldErrors2[name] =
          "The second character must be an alphabetic value (excluding S, L, O, I, B, Z).";
      } else if (lastIndex === 2) {
        fieldErrors2[name] =
          "The third character must be an alphanumeric value (excluding S, L, O, I, B, Z)";
      } else if (lastIndex === 3) {
        fieldErrors2[name] =
          " The fourth character must be a numeric value from 0 to 9.";
      } else if (lastIndex === 4) {
        fieldErrors2[name] =
          "he fifth character must be an alphabetic value (excluding S, L, O, I, B, Z)";
      } else if (lastIndex === 5) {
        fieldErrors2[name] =
          "The sixth character must be an alphanumeric value (excluding S, L, O, I, B, Z)";
      } else if (lastIndex === 6) {
        fieldErrors2[name] =
          "The seventh character must be a numeric value from 0 to 9";
      } else if (lastIndex === 7) {
        fieldErrors2[name] =
          "The eighth character must be an alphabetic value (excluding S, L, O, I, B, Z)";
      } else if (lastIndex === 8) {
        fieldErrors2[name] =
          "The ninth character must be an alphabetic value (excluding S, L, O, I, B, Z).";
      } else if (lastIndex === 9) {
        fieldErrors2[name] =
          "The tenth character must be a numeric value from 0 to 9.";
      } else if (lastIndex === 10) {
        fieldErrors2[name] =
          "The eleventh character must be a numeric value from 0 to 9.";
      }
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors2,
    }));
  };

  console.log(formValues, "formValuesformValuesformValues");
  const resetForm = (resetAll) => {
    if (resetAll) {
      setFormValues(initialValues);
    } else {
      setFormValues({
        ...initialValues,
        status: formValues.status,
        facility: formValues.facility,
        workDate: formValues.workDate,
      });
    }
    setFormErrors("");
    setIsSubmit(false);
    setSelectedValues([]);
  };

  const handleSubmit = (e, resetAll) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    if (validate(formValues)) {
      const formattedValues = {
        ...formValues,

        birthDate: formValues?.birthDate
          ? moment(formValues.birthDate + "T00:00:00.00Z")
          : null,
        eligibletoBillPreventiveVisit: formValues?.eligibletoBillPreventiveVisit
          ? moment(formValues?.eligibletoBillPreventiveVisit + "T00:00:00.00Z")
          : null,
        medicareEffDate: formValues?.medicareEffDate
          ? moment(formValues?.medicareEffDate + "T00:00:00.00Z")
          : null,
        workDate: formValues?.workDate
          ? moment(formValues?.workDate + "T00:00:00.00Z")
          : null,
      };
console.log(formattedValues,"formattedValues")
      dispatch(FormSlice(formattedValues));
      resetForm(resetAll);
    } else {
      console.log("error");
    }
  };

  const options = [
    { value: "alabama", label: "ALABAMA" },
    { value: "alaska", label: "ALASKA" },
    { value: "american samoa", label: "AMERICAN SAMOA" },
    { value: "arizona", label: "ARIZONA" },
    { value: "arkansas", label: "ARKANSAS" },
    { value: "california", label: "CALIFORNIA" },
    { value: "colorado", label: "COLORADO" },
    { value: "connecticut", label: "CONNECTICUT" },
    { value: "delaware", label: "DELAWARE" },
    { value: "district of columbia", label: "DISTRICT OF COLUMBIA" },
    { value: "florida", label: "FLORIDA" },
    { value: "georgia", label: "GEORGIA" },
    { value: "guam", label: "GUAM" },
    { value: "hawaii", label: "HAWAII" },
    { value: "idaho", label: "IDAHO" },
    { value: "illinois", label: "ILLINOIS" },
    { value: "indiana", label: "INDIANA" },
    { value: "iowa", label: "IOWA" },
    { value: "kansas", label: "KANSAS" },
    { value: "kentucky", label: "KENTUCKY" },
    { value: "louisiana", label: "LOUISIANA" },
    { value: "maine", label: "MAINE" },
    { value: "maryland", label: "MARYLAND" },
    { value: "massachusetts", label: "MASSACHUSETTS" },
    { value: "michigan", label: "MICHIGAN" },
    { value: "minnesota", label: "MINNESOTA" },
    { value: "mississippi", label: "MISSISSIPPI" },
    { value: "missouri", label: "MISSOURI" },
    { value: "montana", label: "MONTANA" },
    { value: "nebraska", label: "NEBRASKA" },
    { value: "nevada", label: "NEVADA" },
    { value: "new hampshire", label: "NEW HAMPSHIRE" },
    { value: "new jersey", label: "NEW JERSEY" },
    { value: "new mexico", label: "NEW MEXICO" },
    { value: "new york", label: "NEW YORK" },
    { value: "north carolina", label: "NORTH CAROLINA" },
    { value: "north dakota", label: "NORTH DAKOTA" },
    { value: "northern mariana is", label: "NORTHERN MARIANA IS" },
    { value: "ohio", label: "OHIO" },
    { value: "oklahoma", label: "OKLAHOMA" },
    { value: "oregon", label: "OREGON" },
    { value: "pennsylvanta", label: "PENNSYLVANIA" },
    { value: "puerto rico", label: "PUERTO RICO" },
    { value: "rhode island", label: "RHODE ISLAND" },
    { value: "south carolina", label: "SOUTH CAROLINA" },
    { value: "south dakota", label: "SOUTH DAKOTA" },
    { value: "tennessee", label: "TENNESSEE" },
    { value: "texas", label: "TEXAS" },
    { value: "utah", label: "UTAH" },
    { value: "vermont", label: "VERMONT" },
    { value: "virginia", label: "VIRGINIA" },
    { value: "virgin island", label: "VIRGIN ISLANDS" },
    { value: "washington", label: "WASHINGTON" },
    { value: "west virginia", label: "WEST VIRGINIA" },
    { value: "wisconsin", label: "WISCONSIN" },
    { value: "wyoming", label: "WYOMING" },
  ];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  const handleSelectChange = (selected) => {
    if (selected.length === 1) {
      setFormValues({ ...formValues, state: selected[0] });
    } else {
      setFormValues({
        ...formValues,
        state: Array.isArray(selected) ? selected.join(",") : selected,
      });
    }
    setSelectedValues(selected);
  };
  const formData = [
    {
      title: "State",
      type: "text",
      name: "state",
      select: true,
      placeholder: "Enter State",
    },
    {
      title: "Status",
      name: "status",
      select: true,
      placeholder: "Enter Status",
    },
    {
      title: "Status Service",
      name: "serviceStatus",
      select: true,
      placeholder: "Enter serviceStatus",
    },

    {
      title: "Facility",
      type: "text",
      name: "facility",
      select: false,
      placeholder: "Enter Facility",
    },
    {
      title: "Provider",
      type: "text",
      name: "provider",
      select: false,
      placeholder: "Enter Provider",
    },
    {
      title: "Medicare Beneficiary ID",
      type: "text",
      name: "medicareBeneficiaryId",
      select: false,
      isCapital: true,
      placeholder: "Enter Medicare Beneficiary ID",
    },
    {
      title: "Resident First Name",
      type: "text",
      name: "residentFirstName",
      select: false,
      isCapital: true,
      placeholder: "Enter Resident First Name",
    },
    {
      title: "Resident Last Name",
      type: "text",
      name: "residentLastName",
      select: false,
      isCapital: true,
      placeholder: "Enter Resident Last Name",
    },
    {
      title: "Gender",
      type: "text",
      name: "gender",
      select: true,
      placeholder: "Enter Gender",
    },
    {
      title: "Date of Birth",
      type: "date",
      name: "birthDate",
      select: false,
      usDate: true,
      component: (
        <DatePicker
          selected={formValues["birthDate"]}
          onChange={(date) =>
            setFormValues({
              ...formValues,
              birthDate: date ? moment(date).format("YYYY-MM-DD") : "",
            })
          }
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          className="inputDatepicker"
        />
      ),
    },

    {
      title: "Work Date",
      type: "date",
      name: "workDate",
      select: false,
      usDate: true,
      component: (
        <DatePicker
          selected={formValues["workDate"]}
          onChange={(date) =>
            setFormValues({
              ...formValues,
              workDate: date ? moment(date).format("YYYY-MM-DD") : "",
            })
          }
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          className="inputDatepicker"
        />
      ),
    },
    {
      title: "Eligible to bill Preventive visit",
      type: "date",
      name: "eligibletoBillPreventiveVisit",
      select: false,
      usDate: true,
      component: (
        <DatePicker
          selected={formValues["eligibletoBillPreventiveVisit"]}
          onChange={(date) =>
            setFormValues({
              ...formValues,
              eligibletoBillPreventiveVisit: date
                ? moment(date).format("YYYY-MM-DD")
                : "",
            })
          }
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          className="inputDatepicker"
        />
      ),
    },
    {
      title: "Medicare Eff Date",
      type: "date",
      name: "medicareEffDate",
      select: false,
      usDate: true,
      component: (
        <DatePicker
          selected={formValues["medicareEffDate"]}
          onChange={(date) =>
            setFormValues({
              ...formValues,
              medicareEffDate: date ? moment(date).format("YYYY-MM-DD") : "",
            })
          }
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          className="inputDatepicker"
        />
      ),
    },
    {
      title: "CPT",
      name: "cpt",
      select: true,
      placeholder: "Enter CPT",
    },
    {
      title: "DX",
      type: "text",
      name: "dx",
      select: false,
      placeholder: "Enter DX",
    },
    {
      title: "Room",
      type: "text",
      name: "room",
      select: false,
      placeholder: "Enter Room",
    },
  ];
  const datePickerStyle = { width: "300px" };

  return (
    <div className="container">
      <div>
        <Header />
        <div className="ui divider"></div>
      </div>

      <form>
        <div>
          {formData?.map((item, index) => {
            return !item.select ? (
              <div style={{ width: "50%" }}>
                <div className="field" key={index}>
                  <label>{item.title}</label>
                  <br />

                  {item.component ? (
                    item.component
                  ) : (
                    <input
                      type={item.type}
                      name={item.name}
                      placeholder={item.placeholder}
                      value={
                        item.isCapital
                          ? formValues[item.name]?.toUpperCase()
                          : formValues[item.name]
                      }
                      onChange={handleChange}
                      style={{
                        width: "90%",
                        margin: "10px 0",
                        height: "50px",
                        borderRadius: "10px",
                        color: "black",
                      }}
                    />
                  )}
                </div>
                <p>{formErrors[item.name]}</p>
              </div>
            ) : (
              <div style={{ width: "50%" }}>
                <div className="field">
                  <label>{item.title}</label>
                  <br />
                  {item.name !== "state" && (
                    <select
                      placeholder="Select an option"
                      name={item.name}
                      value={formValues[item.name]}
                      onChange={handleChange}
                      style={{
                        width: "90%",
                        margin: "10px 0",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                    >
                      {item.name === "status" && (
                        <>
                          <option value="Select an option">
                            Select an option
                          </option>
                          <option value="ALREADY PREVENTIVE BILLED">
                            ALREADY PREVENTIVE BILLED
                          </option>
                          <option value="ELIGIBLE TO BILL PREVENTIVE VISIT">
                            {" "}
                            ELIGIBLE TO BILL PREVENTIVE VISIT
                          </option>
                          <option value="DECEASED">DECEASED</option>

                          <option value="HMO">HMO</option>
                          <option value="PATIENT IS ENROLLED IN RAILROAD RETIREMENT MEDICARE ">
                            PATIENT IS ENROLLED IN RAILROAD RETIREMENT MEDICARE
                          </option>

                          <option value="PATIENT NAME IS NOT MATCH">
                            PATIENT NAME IS NOT MATCH
                          </option>
                          <option value="HMO">HMO</option>
                          <option value="NIL">NIL</option>
                        </>
                      )}
                      {item.name === "serviceStatus" && (
                        <>
                          <option value="Select an option">
                            Select an option
                          </option>

                          <option value="PERFORMED">PERFORMED</option>
                          <option value="CANCELLED">CANCELLED</option>
                          <option value="RESCHEDULED">RESCHEDULED</option>
                          <option value="NIL">NIL</option>
                        </>
                      )}

                      {item.name === "cpt" && (
                        <>
                          <option value="Select an option">
                            Select an option
                          </option>
                          <option value="G0439">G0439</option>
                          <option value="G0438">G0438</option>
                          <option value="G0440">G0402</option>
                        </>
                      )}
                      {item.name === "gender" && (
                        <>
                          <option
                            value="Select an option"
                            style={{ color: "red" }}
                          >
                            Select an Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Not Specified">Not Specified</option>
                        </>
                      )}
                    </select>
                  )}

                  {item.name === "state" && (
                    <Select
                      mode="tags"
                      style={{
                        width: "90%",
                        margin: "10px 0",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                      value={selectedValues}
                      // placeholder="Select an Option"
                      placeholder={
                        <span style={{ color: "black" }}>Select an Option</span>
                      }
                      onChange={handleSelectChange}
                      options={options}
                    />
                  )}
                  <br />
                </div>
                <p>{formErrors[item.name]}</p>
              </div>
            );
          })}
        </div>
        <div className="grid-container">
          <button
            type="button"
            onClick={(e) => handleSubmit(e, false)}
            className="fluid ui button blue"
          >
            Save
          </button>
          <button
            type="button"
            onClick={(e) => handleSubmit(e, true)}
            className="fluid ui button blue"
          >
            Submit And Close
          </button>
        </div>
      </form>
      {isSubmit && (
        <div style={{ margin: "20px", color: "green" }}>
          Form submitted successfully!
        </div>
      )}
    </div>
  );
};

export default Home;
