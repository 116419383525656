import React, { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, message } from "antd";
import moment from "moment";
import "./styles.css";
import { Link } from "react-router-dom";

const MyComponent = () => {
  const [medicareBeneficiaryId, setMedicareBeneficiaryId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [dataId, setDataId] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const momentBirthDate = moment(birthDate, "YYYY-MM-DD");
      // const setBirthDate1 = momentBirthDate.isValid() ? momentBirthDate.format("YYYY-MM-DD") : "";
      // const isoString = setBirthDate1 ? setBirthDate1 + "T00:00:00.00Z" : "";

      const isoString = birthDate
        ? moment(birthDate, "MM/DD/YYYY").format("YYYY-MM-DD")
        : "";
      const workedDateValue = isoString ? isoString + "T00:00:00.00Z" : "";
      console.log(workedDateValue, "isoStringisoStringisoString");

      const token = localStorage.getItem("token");
      const response = await axios(
        `https://awv.247healthmedpro.com/api/medicare/filter?medicareBeneficiaryId=${medicareBeneficiaryId}&residentLastName=${lastName}&residentFirstName=${firstName}&birthDate=${
          workedDateValue ? workedDateValue : ""
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data.record);
      setDataId(response.data.record[0]?.id);
      setEditedData({});
    } catch (error) {
      message.error(error);
    }
  };
  const handleValueChange = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleEditClick = () => {
    setEditMode(true);
    setEditedData({
      state: data[0]?.state || "",
      facility: data[0]?.facility || "",
      provider: data[0]?.provider || "",
      medicareBeneficiaryId: data[0]?.medicareBeneficiaryId || "",
      residentFirstName: data[0]?.residentFirstName || "",
      residentLastName: data[0]?.residentLastName || "",
      gender: data[0]?.gender || "",
      birthDate: data[0]?.birthDate || "",
      room: data[0]?.room || "",
      eligibletoBillPreventiveVisit:
        data[0]?.eligibletoBillPreventiveVisit || "",
      status: data[0]?.status || "",
      service: data[0]?.service || "",
      medicareEffDate: data[0]?.medicareEffDate || "",
      cpt: data[0]?.cpt || "",
      dx: data[0]?.dx || "",
      workDate: data[0]?.workDate || "",
      serviceStatus:data[0]?.serviceStatus|| "",
    });
  };
  console.log(birthDate, "birthDate");

  const handleSaveClick = async () => {
    const itemToUpdate = data.find((item) => item.id === dataId);
    if (!itemToUpdate) {
      throw new Error("Item not found");
    }
    const updatedData = {
      ...itemToUpdate,
      ...editedData,
    };
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `https://awv.247healthmedpro.com/api/medicare/update/${dataId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData((prevData) =>
        prevData.map((item) => (item.id === dataId ? updatedData : item))
      );
      setEditMode(false);
      setEditedData(null);
      setEditedData({});
      return response;
    } catch (error) {
      message.error("Error updating data:", error);
    }
  };
  return (
    <div className="container">
      <div className="inputsend">
        <form onSubmit={handleSubmit}>
          <label htmlFor="fname">First Name:</label>
          <input
            className="editinput"
            style={{ borderRadius: "10px", margin: "5px" }}
            type="text"
            id="fname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <label htmlFor="lname">Last Name:</label>
          <input
            className="editinput"
            style={{ borderRadius: "10px", margin: "5px" }}
            type="text"
            id="lname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <label htmlFor="medicareBeneficiaryId">
            Medicare Beneficiary ID:
          </label>
          <input
            className="editinput"
            style={{ borderRadius: "10px", margin: "5px" }}
            type="text"
            id="medicareBeneficiaryId"
            value={medicareBeneficiaryId}
            onChange={(e) => setMedicareBeneficiaryId(e.target.value)}
          />
          <label htmlFor="birthDate">Birth Date:</label>

          <DatePicker
            value={birthDate ? moment(birthDate, "MM/DD/YYYY") : null}
            onChange={(date, dateString) => setBirthDate(dateString)}
            style={{
              // borderRadius: "10px",
              marginLeft: "16px",
              border: "1px solid #035e7b",
              width: "13%",
              height: "32px",
            }}
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <button type="submit" style={{ marginRight: "10px" }}>
              Submit
            </button>
            {/* <button type="submit" style={{width:"7%"}}>Back</button> */}
            <a href="/home" style={{ cursor: "pointer" }}>
              <button type="button" style={{ width: "83px" }}>
                Back
              </button>
            </a>
          </div>
        </form>
      </div>
      <div className="save">
        <div className="editvaluefirst">
          {data && data.length > 0 ? (
            data.map((item) => (
              <div key={item.id} className="values">
                {editMode ? (
                  <>
                    <div className="firstone">State</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.state}
                        onChange={(e) =>
                          handleValueChange("state", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">State</div>
                    <div>{item.state}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Facility</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.facility}
                        onChange={(e) =>
                          handleValueChange("facility", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Facility</div>
                    <div>{item.facility}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Provider</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.provider}
                        onChange={(e) =>
                          handleValueChange("provider", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Provider</div>
                    <div>{item.provider}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Medicare Beneficiary ID</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.medicareBeneficiaryId}
                        onChange={(e) =>
                          handleValueChange(
                            "medicareBeneficiaryId",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Medicare Beneficiary ID</div>
                    <div>{item.medicareBeneficiaryId}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Resident First Name</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.residentFirstName}
                        onChange={(e) =>
                          handleValueChange("residentFirstName", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Resident First Name</div>
                    <div>{item.residentFirstName}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Resident Last Name</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.residentLastName}
                        onChange={(e) =>
                          handleValueChange("residentLastName", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Resident Last Name</div>
                    <div>{item.residentLastName}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Gender</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.gender}
                        onChange={(e) =>
                          handleValueChange("gender", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Gender</div>
                    <div>{item.gender}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Birth Date</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.birthDate}
                        onChange={(e) =>
                          handleValueChange("birthDate", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Birth Date</div>
                    <div>{item.birthDate}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Room</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.room}
                        onChange={(e) =>
                          handleValueChange("room", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone"> Room</div>
                    <div>{item.room}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">
                      ELIGIBLE TO BILL PREVENTIVE VISIT
                    </div>
                    <div>
                      <input
                        type="text"
                        value={editedData.eligibletoBillPreventiveVisit}
                        onChange={(e) =>
                          handleValueChange(
                            "eligibletoBillPreventiveVisit",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">
                      ELIGIBLE TO BILL PREVENTIVE VISIT
                    </div>
                    <div>{item.eligibletoBillPreventiveVisit}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">STATUS</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.status}
                        onChange={(e) =>
                          handleValueChange("status", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">STATUS</div>
                    <div>{item.status}</div>
                  </>
                )}
                   {editMode ? (
                  <>
                    <div className="firstone">SERVICE</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.service}
                        onChange={(e) =>
                          handleValueChange("service", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">SERVICE</div>
                    <div>{item.service}</div>
                  </>
                )}
                   {editMode ? (
                  <>
                    <div className="firstone">SERVICE STATUS</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.serviceStatus}
                        onChange={(e) =>
                          handleValueChange("serviceStatus", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">SERVICE STATUS</div>
                    <div>{item.serviceStatus}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">MEDICARE EFF DATE</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.medicareEffDate}
                        onChange={(e) =>
                          handleValueChange("medicareEffDate", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">MEDICARE EFF DATE</div>
                    <div>{item.medicareEffDate}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">CPT</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.cpt}
                        onChange={(e) =>
                          handleValueChange("cpt", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">CPT</div>
                    <div>{item.cpt}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">DX</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.dx}
                        onChange={(e) =>
                          handleValueChange("dx", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">DX</div>
                    <div>{item.dx}</div>
                  </>
                )}
                {editMode ? (
                  <>
                    <div className="firstone">Worked Date</div>
                    <div>
                      <input
                        type="text"
                        value={editedData.workDate}
                        onChange={(e) =>
                          handleValueChange("workDate", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="firstone">Worked Date</div>
                    <div>{item.workDate}</div>
                  </>
                )}
              </div>
            ))
          ) : (
            <div style={{ color: "black" }}>No data available</div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            {!editMode && (
              <button style={{ width: "300px" }} onClick={handleEditClick}>
                Edit
              </button>
            )}
          </div>
          <div>
            {" "}
            {editMode && (
              <>
                <button style={{ width: "250px" }} onClick={handleSaveClick}>
                  Save
                </button>
                <button
                  style={{ width: "250px" }}
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyComponent;
