import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import Header from "./components/header";
import Container from "./container/container";
import Resetpassword from "./modules/authflow/reset pass";
import Home1 from "./modules/home/index";
import Home from "./modules/home/index";
import MyComponent from "./components/edit/edit";
function App() {
  return (
    <div style={{ height: "100vh" }}>
      <Router>
        <Routes>
          <Route path="/" element={<Container />} />
          <Route path="/inde" element={<Home />} />
          <Route path="/home" element={<Home1 />}></Route>
          <Route path="/header" element={<Header />} />
          <Route path="/main" element={<Container />} />
          <Route path="edit" element={<MyComponent />} />
          <Route path="/accountverify" element={<Resetpassword />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
